'
<!-- <style src="../styles/variables.css"></style> -->
<style scoped>
/* @import '../styles/variables.css'; */
.list-view,
.grid-list {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	user-select: none;
}
.grid-header {
	flex: 0 0 auto;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--grid-header-border-bottom);
	font-size: 0.9em;
}
.grid-rows {
	flex: 1 1 auto;
	overflow: auto;
}
.grid-rows:focus {
	outline: 0 none;
}
.grid-row {
	display: flex;
	flex-direction: row;
}
.grid-cell.col-select label {
	padding: 1px 4px;
	vertical-align: middle;
	border-radius: 4px;
    background: transparent;
	height: auto;
}
.grid-cell.col-select label:hover {
	background-color: var(--grid-row-checkbox-label-hover-background-color);
}
.grid-row.selected {
	background-color: #f2f8ff;
	outline: 1px solid #def;
	background-color: var(--grid-row-selected-background-color);
	outline: 1px solid var(--grid-row-selected-border-color);
}

.grid-row:hover {
	background-color: #f7f7f7;
	outline: 1px solid #EDF0F3;
	background-color: var(--grid-row-hover-background-color);
	outline: 1px solid var(--grid-row-hover-border-color);
}

.grid-row.selected:hover {
	background-color: #f2f8ff;
	outline: 1px solid #def;
	background-color: var(--grid-row-selected-hover-background-color);
	outline: 1px solid var(--grid-row-selected-hover-border-color);
}

.grid-header .grid-row {
	pointer-events: none;
}

.grid-cell {
	flex: 1 1 0;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 12px;
	color: var(--grid-cell-color);
	box-sizing: border-box;
}
.col-select {
	width: 60px;
	flex: 0 0 auto;
	text-overflow: unset;
}
.col-name {
	flex: 1 1 0;
	display: flex;
	flex-direction: row;
	position: relative;
}
.grid-rows .col-name {
	color: var(--grid-rows-col-name-color);
	font-weight: bold;
}
.item-icon {
	display: inline-block;
	width: 24px;
	text-align: center;
	margin-right: 8px;
}

.thumbnails {
	height: 24px;
	width: 24px;
}

.thumbnails img {
	width: 100%;
	border-radius: 4px;
	filter: var(--image-brightness);
}

.item-name {
	display: inline-block;
	overflow-wrap: anywhere;
}
.item-type-folder .col-name {
	color: #2E88D6;
}
.col-owner {
	width: 180px;
	flex: 0 0 auto;
}
.col-size {
	width: 140px;
	flex: 0 0 auto;
}
.col-added,
.col-updated,
.col-trashed,
.col-shared {
	width: 160px;
	flex: 0 0 auto;
}
.col-menu {
	width: 60px;
	flex: 0 0 auto;
}

.grid-cell:first-child {
	padding-left: 28px;
}


.icon-folder,
.icon-file {
	vertical-align: bottom;
}

.action-open-item {
	color: inherit;
}
.action-open-item:hover {
	text-decoration: underline;
}

.action-context {
	border-radius: 50%;
	width: 24px;
	height: 24px;
	padding: 0;
	background-color: transparent;
	border: 1px solid var(--action-context-border-color);
	user-select: none;
}
.action-context:active {
	outline: 0 none;
	border-color: #999;
}



.item-block {
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
}





.item-name-block {
	display: inline-block;
	position: relative;
	max-width: 100%;
	flex: 0 1 auto;
}
.editing .item-name {
	visibility: hidden;
}
.item-name-editor {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
}
.item-name-editor-input {
	font-size: 1em;
	border: 1px solid var(--item-name-editor-input-border-color);
	border-radius: 0;
	padding: 8px 0;
	width: 105%;
	margin: -8px 0;
	max-width: 100vw;
	min-width: 5em;
}



.upload-progress-bar {
	display: block;
	width: 100%;
	height: 5px;
	position: absolute;
	bottom: 0;
}
.upload-progress-loaded {
	display: block;
	height: 100%;
	width: 0%;
	background-color: rgba(0, 200, 0, 0.5);
	transition: width 200ms linear;
	background-image: linear-gradient(to right, rgba(255,255,255,0) 30%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 80%);
	background-size: 600px;
	background-position: 0 0;
	animation: backgroundrollx 1s linear infinite;
}
@keyframes backgroundrollx {
	0% { background-position-x: 600px; }
	100% { background-position-x: 0; }
}

.upload-progress-enter-active {
	transition: opacity 0.5s;
}
.upload-progress-leave-active {
	transition: opacity 1s 1s, background-color 0.5s;
}
.upload-progress-enter,
.upload-progress-leave-to {
	opacity: 0;
}

.draggable-item {
	flex: 1 1 auto;
}
.drag-source {
	/* accepted, but not under pointer */
	opacity: 0.2;
	filter: grayscale(100%);
}
.drop-allowed {
	/* valid drop data */
	outline: 1px dashed rgba(0,0,0,.1);
}
.drop-forbidden {
	/* invalid drop data */
	opacity: 0.8;
}
.drop-in {
	/* accepted and not under pointer */
	outline: 1px dashed green;
}
.ditem-drag-image {
	background-color: #fff;
	border-radius: 3px;
}
.drag-empty {
	padding: 0.5em;
}
.drag-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
.drag-list-item {
	padding: 0.5em;
}
.drag-summary {
	padding: 0.5em;
}



.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-separator {
	display: none;
}
.menu-list li + li > .menu-separator {
	display: block;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: var(--menu-separator-opacity);
}
.menu-submenu {
	background: var(--menu-popover-background-color);
	border: 1px solid var(--menu-popover-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}

@media only screen and (max-width : 800px) {
	.col-size, .col-owner {
		display: none;
	}
}

@media only screen and (max-width : 500px) {
	.col-added, .col-updated, .col-shared {
		display: none;
	}
}


</style>

<template>
	<section class="list-view">
		<div class="grid-list" role="grid">
			<div class="grid-header" role="rowgroup">
				<div class="grid-row" role="row">
					<span v-if="colConfig.selection.show" class="grid-cell col-select" role="columnheader" aria-sort="none"></span>
					<span class="grid-cell col-name" role="columnheader" aria-sort="none">Name</span>
					<span v-if="colConfig.owner.show" class="grid-cell col-owner" role="columnheader" aria-sort="none">Owner</span>
					<span v-if="colConfig.size.show" class="grid-cell col-size" role="columnheader" aria-sort="none">Size</span>
					<span v-if="colConfig.added.show" class="grid-cell col-added" role="columnheader" aria-sort="none">Added</span>
					<span v-if="colConfig.updated.show" class="grid-cell col-updated" role="columnheader" aria-sort="none">Updated</span>
					<span v-if="colConfig.trashed.show" class="grid-cell col-trashed" role="columnheader" aria-sort="none">Removed</span>
					<span v-if="colConfig.shared.show" class="grid-cell col-shared" role="columnheader" aria-sort="none">Shared</span>
					<span class="grid-cell col-menu" role="columnheader" aria-sort="none"></span>
				</div>
			</div>
			<div class="grid-rows" role="rowgroup" tabindex="-1">

				<drop tag="div"
					class="grid-row"
					role="row"
					v-for="item in list" :key="item.id"
					:class="{
						'item-type-folder': item.folderType,
						'item-type-file': item.type === 'FILE',
						selected: selection && selection.indexOf(item) >= 0,
						editing: editContext && editContext.item === item
					}"
					@drop="moveItems(selection, item.id)"
					mode="cut"
					accepts-type="ditems"
					:accepts-data="(selection) => canAcceptDrop(item, selection)"
					@pointerdown="pressItem(item, $event)"
					@contextmenu.prevent="onItemContextMenu($event, item)">



					<span v-if="colConfig.selection.show" class="grid-cell col-select" role="cell"><label><input type="checkbox" class="checked" @input.stop="onCheckChange(item, $event.target.checked)" :ref="syncSelection" :value="item"/></label></span>

					<span class="grid-cell col-name" role="cell" :title="item.name">

						<drag class="draggable-item" :data="selection" @cut="onDragCut(selection)" type="ditems" :disabled="!allowDrag">

							<span v-if="item.type === 'FOLDER'" class="item-block">
								<span class="item-icon" :data-type="item.folderType"><img src="@/assets/icon-folder.svg" class="icon-folder" draggable="false" /></span>
								<span class="item-name-block">
									<span class="item-name" v-if="allowNavigation"><router-link class="action-open-item" :to="'/d/' + encodeURIComponent(item.id)" draggable="false">{{ (editContext && editContext.item === item ? editContext.value : item.name) }}</router-link></span>
									<span class="item-name" v-else>{{ (editContext && editContext.item === item ? editContext.value : item.name) }}</span>
									<span class="item-name-editor" v-if="editContext && editContext.item === item">
										<input v-autofocus v-rename-input class="item-name-editor-input" type="text" v-model="editContext.value" @blur="editContext && confirmItemRename(editContext)" @keypress.enter="confirmItemRename(editContext)" @keydown.esc="cancelItemRename(editContext)" />
									</span>
								</span>
							</span>
							<span v-else class="item-block">
								<span v-if="item.thumbnails['64x64']" class="item-icon thumbnails" :data-type="item.fileType"><img :src="item.thumbnails['64x64'].url" class="icon-file" draggable="false" /></span>
								<span v-else class="item-icon" :data-type="item.fileType"><img src="@/assets/icon-file.svg" class="icon-file" draggable="false" /></span>
								<span class="item-name-block">
									<span class="item-name" v-if="allowNavigation"><router-link class="action-open-item" :to="'/f/' + encodeURIComponent(item.id)" draggable="false">{{ (editContext && editContext.item === item ? editContext.value : item.name) }}</router-link></span>
									<span class="item-name" v-else>{{ (editContext && editContext.item === item ? editContext.value : item.name) }}</span>
									<span class="item-name-editor" v-if="editContext && editContext.item === item">
										<input v-autofocus v-rename-input class="item-name-editor-input" type="text" v-model="editContext.value" @blur="editContext && confirmItemRename(editContext)" @keypress.enter="confirmItemRename(editContext)" @keydown.esc="cancelItemRename(editContext)" />
									</span>
								</span>
							</span>

							<template v-slot:drag-image>
								<div class="ditem-drag-image">
									<div v-if="selection && !selection.length" class="drag-empty">
										Empty
									</div>
									<div v-if="selection && selection.length < 4" class="drag-list">
										<div v-for="dragItem in selection" :key="dragItem.id" class="drag-list-item" :class="{'file-item':dragItem.type==='FILE','folder-item':dragItem.type==='FOLDER'}">
											<i class="fa" :class="{'fa-file':dragItem.type==='FILE','fa-folder':dragItem.type==='FOLDER'}"></i>
											<span class="drag-item-name">{{ dragItem.name }}</span>
										</div>
									</div>
									<div v-else class="drag-summary">
										<i class="fa fa-layer-group"></i>
										{{ selection && selection.length }} Items
									</div>
								</div>
							</template>
						</drag>

						<transition name="upload-progress">
							<span class="upload-progress-bar" v-if="item.uploadState === 'PENDING'" :class="{'upload-succeeded':item.uploadState === 'SUCCESS', 'upload-failed':item.uploadState === 'ERROR'}">
								<span class="upload-progress-loaded" :style="getProgressStyle(item, item.uploadedBytes, item.bytes)"></span>
							</span>
						</transition>

					</span>

					<span v-if="colConfig.owner.show" class="grid-cell col-owner" role="cell">{{ item.owner.username }}</span>
					<span v-if="colConfig.size.show" class="grid-cell col-size" role="cell">{{ formatBytes(+item.bytes) }}</span>
					<span v-if="colConfig.added.show" class="grid-cell col-added" role="cell" :title="item.createdAt">{{ formatDate(item.createdAt) }}</span>
					<span v-if="colConfig.updated.show" class="grid-cell col-updated" role="cell" :title="item.updatedAt">{{ formatDate(item.updatedAt) }}</span>
					<span v-if="colConfig.trashed.show" class="grid-cell col-trashed" role="cell" :title="item.trashedAt">{{ formatDate(item.trashedAt) }}</span>
					<span v-if="colConfig.shared.show" class="grid-cell col-shared" role="cell" :title="item.sharedAt">{{ formatDate(item.sharedAt) }}</span>
					<span class="grid-cell col-menu" role="cell"><button class="action-context" type="button" @click.capture="onItemContextMenu($event, item)" :key="'contextbutton_' + item.id"><i class="fa fa-ellipsis-v"></i></button></span>
				</drop>

			</div>
		</div>

		<context-menu ref="selectionContextMenu" v-slot="{context}">
			<ul class="menu-list" v-if="context && context.data && context.data.length > 0">
				<li v-if="context.data.length === 1">
					<div class="menu-action" @click="openItem(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Open</span>
					</div>
				</li>
				<li class="menu-group" v-if="context.data.length === 1 && (canOpenInWaves(context.data[0]) || canOpenInLines(context.data[0]))">
					<div class="menu-submenu">
						<ul class="menu-list">
							<li v-if="canOpenInWaves(context.data[0])">
								<div class="menu-action" @click="launchInWaves(context.data[0])">
									<span class="menu-icon"></span>
									<span class="menu-label">Waves</span>
								</div>
							</li>
							<li v-if="canOpenInLines(context.data[0])">
								<div class="menu-action" @click="launchInLines(context.data[0])">
									<span class="menu-icon"></span>
									<span class="menu-label">Lines</span>
								</div>
							</li>
							<li v-if="isTekScopeUser && isTekscopeSupportedFile(context.data[0])">
								<div @click="launchInTekscope(context.data[0])" class="menu-action">
									<span class="menu-icon"></span>
									<span class="menu-label">TekScope</span>
								</div>
							</li>
						</ul>
					</div>
					<div class="menu-parent">
						<span class="menu-icon"></span>
						<span class="menu-label">Open With&hellip;</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canDownloadItems(context.data)">
					<hr class="menu-separator">
					<div class="menu-action" @click="downloadFiles(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Download</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canEdit(context.data[0])">
					<div class="menu-action" @click="startItemRename(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Rename</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canEdit(context.data[0])">
					<div class="menu-action" @click="editItemMembers(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Share</span>
					</div>
				</li>
				<li v-if="(context.data[0].owner.id === userId && canEdit(context.data[0])) || (context.data[0].owner.id !== userId && canEdit(context.data[0])) && (!areAllItemsInShareSilo(context.data))">
					<hr class="menu-separator">
					<div class="menu-action" @click="deleteItems(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Remove</span>
					</div>
				</li>
				<li v-else-if="((!context.data[0].owner.id !== userId && !canEdit(context.data[0])) || !context.data[0].owner.id !== userId && canEdit(context.data[0])) && (areAllItemsInShareSilo(context.data))">
					<hr class="menu-separator">
					<div class="menu-action" @click="removeMember(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Remove Share</span>
					</div>
				</li>
			</ul>
		</context-menu>

	</section>

</template>

<script>
import { Drag, Drop } from "vue-easy-dnd";
import ContextMenu from '@/components/ContextMenu.vue';
import EnvVars from '@/modules/env-vars';
import { promiser } from '@/modules/promise-util';
import driveService from '@/modules/drive-service';
import { byteFormatFilter, dateFormatFilter } from '@/modules/filters.js';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '@/store/rootStore';
import { useAuthStore } from "@/store/auth";

var IS_FILE_TYPES = ['CSV', 'WFM', 'TSS', 'ISF', 'VCD'];
var TEKSCOPE_FILE_TYPES = ['TSS', 'WFM'];
function getFileType(file) {
	if (file == null) {
		return '';
	}
	if (file.fileType === 'ZIP' & /\.tss$/i.test(file.name)) {
		// Workaround for the pipeline identifying TSS files as zip (technically true, but it's a special type of zip)
		return 'TSS';
	}
	if (typeof file.fileType === 'string' && file.fileType !== '') {
		return file.fileType.toUpperCase();
	}
	var name = (typeof file.name === 'string') ? file.name : '';
	var idx = name.lastIndexOf('.');
	var ext = (idx < 0) ? '' : name.slice(idx + 1);
	return ext.toUpperCase();
}

function isTekscopeFile(file) {
	if (file == null) {
		return false;
	}
	// TSS, WFM, ISF
	const type = getFileType(file);
	return TEKSCOPE_FILE_TYPES.indexOf(type) >= 0;
}

function isInitialStateFile(file) {
	if (file == null) {
		return false;
	}
	// Only TSS, WFM, ISF, CSV, and VCD files
	const type = getFileType(file);
	return IS_FILE_TYPES.indexOf(type) >= 0;
}

function isMacOS() {
	return /Mac OS X/.test(navigator.userAgent);
}

export default {
	name: 'ListView',

	props: {
		list: Array,
		selection: Array, // bind selection,
		columns: {
			default: 'size,added'
		},
		allowDrag: {
			default: true
		},
		allowNavigation: {
			default: true
		}
	},

	emits: ['update:selection', 'contextmenu'],

	components: {
		Drag,
		Drop,
		ContextMenu
	},

	data () {
		return {
			editContext: null,
			lastSelected: null,
			loadError: '',
			downloadUrl: '',
		};
	},


	computed: {
		...mapState(useAuthStore, {
			user: 'user',
			refreshToken: 'refreshToken'
		}),
		isTekScopeUser() {
			return this.user.claims.includes('drive:tekscope:openin');
		},
		...mapState(useRootStore, {
			userId: (store) => store.userInfo.id,
			sharesSiloId: (store) => {
				if (!store.silos.SHARES.id) {
					return null;
				}
				return store.silos.SHARES.id;
			}
		}),
		allowSelection () {
			return Array.isArray(this.selection);
		},

		colConfig () {
			const colstr = (typeof this.columns === 'string') ? this.columns : 'size,added';
			const cols = colstr.toLowerCase().split(',');
			return {
				selection: {
					show: this.allowSelection
				},
				owner: {
					show: cols.indexOf('owner') >= 0
				},
				size: {
					show: cols.indexOf('size') >= 0
				},
				added: {
					show: cols.indexOf('added') >= 0
				},
				updated: {
					show: cols.indexOf('updated') >= 0
				},
				trashed: {
					show: cols.indexOf('trashed') >= 0
				},
				shared: {
					show: cols.indexOf('shared') >= 0
				}
			};
		}
	},

	methods: {
		...mapActions(useRootStore, {
			CONFIRM_LAUNCH_ITEM: 'CONFIRM_LAUNCH_ITEM',
			MOVE_ITEMS: 'MOVE_ITEMS',
			CONFIRM_DELETE_ITEMS: 'CONFIRM_DELETE_ITEMS',
			CONFIRM_REMOVE_SHARE: 'CONFIRM_REMOVE_SHARE',
			SET_ITEM_NAME: 'SET_ITEM_NAME',
			EDIT_SHARE_MEMBERS: 'EDIT_SHARE_MEMBERS'
		}),
		formatDate(value, format) {
			return dateFormatFilter(value, format);
		},
		formatBytes(value) {
			return byteFormatFilter(value);
		},
		isTekscopeSupportedFile(item) {
			if (!item || !item.id || item.type !== 'FILE') {
				return false;
			}
			return isTekscopeFile(item);
		},

		isItemChildOfShares(item) {
			if (item == null) {
				return false;
			}
			return this.sharesSiloId === item.parentFolderId;
		},

		areAllItemsInShareSilo(items) {
			if (!Array.isArray(items)) {
				return false;
			}
			return items.every((item) => {
				return this.isItemChildOfShares(item);
			});
		},
		itemSelection (items) {
			if (Array.isArray(items)) {
				this.$emit('update:selection', items);
			}
			return this.selection;
		},

		onCheckChange (item, checked) {
			if (checked) {
				this.$emit('update:selection', [...this.selection, item]);
			} else {
				const selectionCopy = [...this.selection];
				selectionCopy.splice(selectionCopy.indexOf(item), 1);
				this.$emit('update:selection', selectionCopy);
			}
		},

		openItem(item) {
			if (!item || !item.id) {
				return;
			}
			if (item.type === 'FOLDER') {
				this.$router.push('/d/' + encodeURIComponent(item.id));
			} else {
				this.$router.push('/f/' + encodeURIComponent(item.id));
			}
		},

		canDownloadItems(items) {
			return Array.isArray(items)
				&& items.every((item) => item != null && item.type === 'FILE');
		},

		canOpenInWaves(item) {
			if (!item || !item.id || item.type !== 'FILE') {
				return false;
			}
			return isInitialStateFile(item);
		},

		canOpenInLines(item) {
			if (!item || !item.id || item.type !== 'FILE') {
				return false;
			}
			return isInitialStateFile(item);
		},

		canEdit(item) {
			if (!item || !item.id) {
				return false;
			}
			return item.permissions.edit;
		},

		launchInWaves(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.WAVES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			// Confirm before launching (if small enough, confirm is skipped)
			this.CONFIRM_LAUNCH_ITEM(file)
				.then(() => {
					// Confirmed! Launching file...
					window.open(url);
					console.log('Launching File...', file);
				})
				.catch((reason) => {
					console.log('Launch Cancelled');
				});
		},

		launchInLines(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.LINES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			// Confirm before launching (if small enough, confirm is skipped)
			this.CONFIRM_LAUNCH_ITEM(file)
				.then(() => {
					// Confirmed! Launching file...
					window.open(url);
					console.log('Launching File...', file);
				})
				.catch((reason) => {
					console.log('Launch Cancelled');
				});
		},

		pressItem (item, event) {
			if (!this.allowSelection) {
				return;
			}
			const nodeName = event.target.nodeName.toUpperCase();
			if (nodeName === 'INPUT' || nodeName === 'LABEL') {
				return;// ignore
			}
			if (event.shiftKey && this.lastSelected) {
				const from = this.list ? this.list.indexOf(this.lastSelected) : -1;
				let to = this.list ? this.list.indexOf(item) : -1;
				if (from >= 0 && to >= 0) {
					this.lastSelected = item;
					// TODO: This doesn't necessarily clear other selected files, it just adds to a range
					let newSelection = this.list.slice(Math.min(from, to), Math.max(from, to) + 1);
					let currentSelection = this.selection.filter(item => newSelection.indexOf(item) < 0).concat(newSelection);
					this.itemSelection(currentSelection);
					return;
				}
			} else if (isMacOS() && event.metaKey && this.selection || !isMacOS() && event.ctrlKey && this.selection) {
				let to = this.selection.indexOf(item);
				if (to < 0) {
					this.itemSelection(this.selection.concat(item));
				} else {
					this.itemSelection(this.selection.slice(0, to).concat(this.selection.slice(to + 1)));
				}
				this.lastSelected = item;
				return;
			} else if (isMacOS() && event.ctrlKey && this.selection.length) {
				this.itemSelection(this.selection);
				return;
			}

			this.itemSelection([item]);
			this.lastSelected = item;
		},

		moveItems (items, folderId) {
			if (!Array.isArray(items)) {
				throw new Error('Expected Directory Items');
			}
			if (typeof folderId !== 'string') {
				throw new Error('Expected Folder ID');
			}

			this.MOVE_ITEMS({ items:items, parentFolderId:folderId });

			this.itemSelection([]);

		},

		canAcceptDrop (item, selection) {
			return (typeof item === 'string'
				|| item != null && item.type === 'FOLDER')
				&& Array.isArray(selection)
				&& selection.length > 0
				&& selection.every((selItem) => selItem != null && selItem !== item);
		},

		onDragCut (selection) {
			// remove/hide selection from current view?
			// Note: move typically means the parentFolderId is changing, this may not be necessary
		},

		getProgressStyle (meta, loaded, total) {
			if (meta == null || !isFinite(meta.uploadedBytes)) {
				return null;
			}
			var percent = ((meta.uploadedBytes / meta.bytes) * 100);
			return {
				width: percent + '%'
			};
		},

		onItemContextMenu (event, item) {
			let list = this.selection?.length ? this.selection : [item];
			this.itemSelection(list);
			// const list = (this.allowSelection) ? this.itemSelection() : [item];
			if (!Array.isArray(list) || !list.length) {
				return;
			}

			// TODO: handle pointer/touch events
			const contextEvent = {
				originalEvent: event,

				type: event.type,
				clientX: event.clientX,
				clientY: event.clientY,
				offsetX: event.offsetX,
				offsetY: event.offsetY,
				pageX: event.pageX,
				pageY: event.pageY,
				x: event.x,
				y: event.y,

				target: event.target,

				altKey: event.altKey,
				shiftKey: event.shiftKey,
				ctrlKey: event.ctrlKey,

				button: event.button,
				buttons: event.buttons,
				which: event.which,

				defaultPrevented: false,

				preventDefault() {
					this.defaultPrevented = true;
				}
			};
			this.$emit('contextmenu', contextEvent);

			if (!contextEvent.defaultPrevented) {
				this.$refs.selectionContextMenu.open(event, {
					data: list,
					close: () => {
						this.$refs.selectionContextMenu.close();
					}
				});
			}
		},

		downloadFiles (items) {
			const files = (!Array.isArray(items) || !items.length) ? []:
				items.filter((item) => item.type === 'FILE');
			if (!files.length) {
				return;
			}

			files.forEach((file)=>{
				driveService.downloadFile(file.id);
			});

		},

		launchInTekscope(file) {
			driveService.getFileDownloadUrl(file)
				.then((url) => {
					this.downloadUrl = url;
				})
				.then(() => {
					const fileId = file.id;
					const tekscopeUrl = `scopeapp://f/${fileId}?dl=${encodeURIComponent(this.downloadUrl)}`;
					window.open(tekscopeUrl, '_blank');
				});
		},

		deleteItems (items) {
			if (!Array.isArray(items) || !items.length) {
				return;
			}

			this.CONFIRM_DELETE_ITEMS(items)
				.then(() => {
					// reset selection and edit context
					this.itemSelection([]);
					this.editContext = null;
				})
				.catch((reason) => {
					console.log('Deletion Cancelled');
				});

		},

		removeMember (item) {
			this.CONFIRM_REMOVE_SHARE(item)
				.then(() => {
					this.itemSelection([]);
					this.editContext = null;
				})
				.catch((reason) => {
					console.log('Share Removal Cancelled');
				});
		},

		startItemRename: function (item) {
			if (this.editContext) {
				// clear edit context
				this.confirmItemRename(this.editContext);
			}

			if (item) {

				const resolveable = promiser();
				const state = this;

				this.editContext = {
					item: item,
					value: item.name,
					originalValue: item.name,
					promise: resolveable.promise,
					commit: (value) => {
						this.SET_ITEM_NAME({ item, name: this.editContext.value });
						this.editContext = null;
						resolveable.resolve(value);
					},
					cancel: (reason) => {
						this.editContext = null;
						resolveable.reject(reason);
					}
				};

				// return new edit promise
				return this.editContext.promise;
			}

			// no new edit context, resolve as non-edit
			return Promise.resolve(null);
		},

		confirmItemRename: function (editContext, name) {
			if (editContext && typeof editContext.commit === 'function') {
				editContext.commit(name);
			}
		},

		cancelItemRename: function (editContext) {
			if (editContext && typeof editContext.cancel === 'function') {
				editContext.cancel();
			}
		},

		editItemMembers (item) {
			this.EDIT_SHARE_MEMBERS(item);
		},

		syncSelection(el) {
			if (!el || !this.selection || this.selection.length <= 0) {
				return;
			}
			if (el && el._value && this.selection.includes(el._value)) {
				el.checked = true;
			} else {
				el.checked = false;
			}
		}
	}

};
</script>

